<template>
  <div class="height1">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item label="药品分类">
                <el-select v-model="searchForm.categoryId" placeholder="请选择" size="small" style="width: 120px" @change="Search">
                  <el-option :label="item.categoryName" :value="item.categoryId" v-for="(item, index) in categoryData" :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="">
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="药品名称" @keyup.enter.native="Search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" height="calc(100% - 80px)">
          <el-table-column type="index" label="编号" width="50"></el-table-column>
          <el-table-column prop="drugName" label="药品名称"></el-table-column>
          <el-table-column prop="category.categoryName" label="药品分类" align="center"> </el-table-column>
          <el-table-column prop="specification" label="药品规格" align="center">
            <template slot-scope="scope">
              <p v-if="scope.row.specification">{{ scope.row.specification }}{{ scope.row.specificationUnit }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="productFactory" label="厂家" align="center"></el-table-column>
          <el-table-column prop="amount" label="销售量" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.amount">{{ scope.row.amount }}</span>
              <span v-if="scope.row.isSeparate">（{{ scope.row.separateNumberUnit }}）</span>
              <span v-else>（{{ scope.row.specificationUnit }}）</span>
            </template>
          </el-table-column>
          <el-table-column prop="saleMoney" label="销售金额(元)" align="center">
            <template slot-scope="scope">
              {{ scope.row.saleMoney.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" align="center" width="150px">
            <template slot-scope="scope">
              <span class="primary optionBtn" @click="prviewBtn(scope.row)">明细</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
    <!--    查看订单    -->
    <el-dialog :visible.sync="orderDialog" width="55%">
      <el-table :data="orderData" stripe style="width: 100%" height="440px">
        <el-table-column type="index" label="编号" width="50"></el-table-column>
        <el-table-column prop="orderNum" label="订单编号" />
        <el-table-column prop="doctorName" label="开方医生" align="center" />
        <el-table-column prop="amount" label="开药量" align="center">
          <template slot-scope="scope"> 
            {{ scope.row.amount }}{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }} 
          </template>
        </el-table-column>
        <el-table-column prop="money" label="药品单价(元)" align="center" >
          <template slot-scope="scope">
              {{ scope.row.money.toFixed(2) }}/{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }}
            </template>
        </el-table-column>
        <el-table-column prop="countMoney" label="药品总价(元)" align="center" >
          <template slot-scope="scope">
              {{ scope.row.countMoney.toFixed(2) }}
            </template>
        </el-table-column>
        <el-table-column prop="addTime" label="添加时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.addTime | timefilters }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @current-change="changePage2" :page-size="pageSize2" :current-page="pageIndex2" :total="dataTotal2" layout="total, prev, pager, next, jumper"></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import { Outpatient } from "@/components/DrugDomain/Outpatient.js";
import { Department } from "@/components/HospitalDomain/Department.js";
export default {
  data() {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug);
    var department = new Department(this.TokenClient, this.Services.Authorization);
    return {
      OutpatientDomain: outpatient,
      Department: department,
      headers: { "Content-Type": "multipart/form-data;charset=UTF-8" },
      tableData: [],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      categoryData: [],
      searchForm: {
        keyWord: "",
        categoryId: 0,
      },
      orderData: [],
      orderDialog: false,
      pageIndex2: 1,
      dataTotal2: 1,
      pageSize2: 0,
      orgId: 0,
    };
  },
  mounted() {
    this.LoadCategory();
    this.getList();
  },
  methods: {
    getDetail() {
      var _this = this;
      _this.orderData = [];
      _this.OutpatientDomain.MZDrugSellOrderDetail(
        this.orgId,
        this.id,
        this.pageIndex2,
        function (data) {
          _this.orderData = data.data.results;
          _this.pageIndex2 = data.data.pageIndex;
          _this.pageSize2 = data.data.pageSize;
          _this.dataTotal2 = data.data.dataTotal;
        },
        function (err) {
          console.log(err);
        }
      );
    },
    changePage2(pageIndex) {
      this.pageIndex2 = pageIndex;
      this.getDetail();
    },
    prviewBtn(item) {
      this.id = item.drugId;
      this.orderDialog = true;
      this.getDetail();
    },
    handleOption(e) {
      this.searchForm.departmentId = e[0];
      this.pageIndex = 1;
      this.getList();
    },
    LoadCategory() {
      var _this = this;
      _this.OutpatientDomain.GetOutpatientCategories(
        function (data) {
          _this.categoryData = data.data.categories;
          _this.categoryData.unshift({ categoryId: 0, categoryName: "全部" });
        },
        function (err) {
          console.log(err);
        }
      );
    },
    changePage(pageIndex) {
      this.pageIndex = pageIndex;
      this.getList();
    },
    getList() {
      var _this = this;
      _this.tableData = [];
      var item = _this.searchForm;
      _this.OutpatientDomain.MZDrugSellInfo(
        0,
        item.keyWord,
        item.categoryId,
        this.pageIndex,
        function (data) {
          _this.tableData = data.data.results;
          _this.pageIndex = data.data.pageIndex;
          _this.pageSize = data.data.pageSize;
          _this.dataTotal = data.data.dataTotal;
        },
        function (err) {
          console.log(err);
        }
      );
    },
    Search(event) {
      this.pageIndex = 1;
      this.getList();
    },
  },
};
</script>

<style scoped>
.dialog-form {
  width: 85%;
}
</style>
